import { FetchAirportStart, AirportActionTypes, FetchedAirportSuccess } from './airport.actions';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

const AIRPORT_API = 'https://services-api.ryanair.com/views/locate/5/airports/en/active';

const FLIGHTS_API =
  'https://services-api.ryanair.com/timtbl/v3/journeys/MAD/WRO' +
  '?departureDateFrom=2019-01-10&departureDateTo=2019-01-10&key=c75263dd5ffbda4698843257b2e68fbb';

@Injectable()
export class AirportEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  @Effect()
  fetchAirports = this.actions$.pipe(
    ofType<FetchAirportStart>(AirportActionTypes.FETCH_START),
    switchMap(() => this.http.get(AIRPORT_API).pipe(map(result => new FetchedAirportSuccess(result))))
  );
}
