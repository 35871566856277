import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { IconModule } from '@ryanair/icon';
import { RyButtonModule } from '@ryanair/button';
import { InlineSVGModule } from 'ng-inline-svg';

import { MaterialModule } from './material.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SearchPanelComponent } from './components/search-panel/search-panel.component';
import { ResultsComponent } from './components/results/results.component';
import { SearchPanelContainerComponent } from './components/search-panel-container/search-panel-container.component';
import { ResultsContainerComponent } from './components/results-container/results-container.component';

import { AirportEffects } from './state/airport.effects';
import { airportReducer } from './state/airport.reducer';
import { FlightsEffects } from './state/flights.effects';
import { flightsReducer } from './state/flights.reducer';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SearchPanelComponent,
    ResultsComponent,
    SearchPanelContainerComponent,
    ResultsContainerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IconModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    RyButtonModule,
    InlineSVGModule.forRoot({
      baseUrl: `https://assets.ryanair.com/resources/ui/icons/`
    }),
    StoreModule.forRoot({ airport: airportReducer, flights: flightsReducer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    }),
    EffectsModule.forRoot([AirportEffects, FlightsEffects])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
