import { FlightsQuery, Flights } from './../model/flights.model';
import { Action } from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

export enum FlightsActionTypes {
  FETCH_START = '[Flights] FETCH_START',
  FETCH_SUCCESS = '[Flights] FETCH_SUCCESS',
  FETCH_ERROR = '[Flights] FETCH_ERROR',
  Reset = '[Flights] Reset'
}

export class FetchFlightsStart implements Action {
  readonly type = FlightsActionTypes.FETCH_START;
  constructor(public payload: FlightsQuery) {}
}

export class FetchedFlightsSuccess implements Action {
  readonly type = FlightsActionTypes.FETCH_SUCCESS;
  constructor(public payload: Flights[]) {}
}

export class FetchedFlightsError implements Action {
  readonly type = FlightsActionTypes.FETCH_ERROR;
  constructor(public errorResponse: HttpErrorResponse) {}
}

export type FlightsActions = FetchFlightsStart | FetchedFlightsSuccess | FetchedFlightsError;
