import { Component, Input } from '@angular/core';
import { Flight } from 'src/app/model/flights.model';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent {
  @Input() flights: Flight[];
  @Input() airports: Map<string, string>;

  toAirportAvatar(code: string): string {
    return `https://www.ryanair.com/etc/designs/ryanair/frontend/resources/ui/illustrations/destinations/${code}.png`;
  }

  toAirportName(code: string): string {
    return this.airports.get(code);
  }

  flightSelectLink(departureDateTime: string, departureAirportCode: string, arrivalAirportCode: string): string {
    const params: Record<string, string | number | boolean> = {
      adults: 1,
      teens: 0,
      children: 0,
      infants: 0,
      dateOut: departureDateTime,
      dateIn: '',
      isConnectedFlight: false,
      isReturn: false,
      discount: 0,
      promoCode: '',
      originIata: departureAirportCode,
      destinationIata: arrivalAirportCode,
      tpAdults: 1,
      tpTeens: 0,
      tpChildren: 0,
      tpInfants: 0,
      tpStartDate: departureDateTime,
      tpEndDate: '',
      tpDiscount: 0,
      tpPromoCode: '',
      tpDepartureIata: departureAirportCode,
      tpDestinationIata: arrivalAirportCode
    };

    return `https://www.ryanair.com/ie/en/trip/flights/select?${new URLSearchParams(params as Record<string, string>)}`;
  }
}
