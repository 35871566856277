import {Flight} from './../../model/flights.model';
import { Component } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';

import { getErrorResponse, getFlightsGrouped, getLoadingState, getPristineState } from 'src/app/state/flights.reducer';
import {getAirportsMap} from './../../state/airport.reducer';
import {MatSnackBar} from '@angular/material';
import {HttpErrorResponse} from '@angular/common/http';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-results-container',
  templateUrl: './results-container.component.html',
  styleUrls: ['./results-container.component.scss']
})
export class ResultsContainerComponent {
  flights$: Observable<Map<string, Flight[][]>>;
  airports$: Observable<Map<string, string>>;
  errorResponse$: Observable<HttpErrorResponse>;
  pristineState$: Observable<boolean>;
  loadingState$: Observable<boolean>;

  constructor(private store: Store<any>, private snackBar: MatSnackBar) {
    this.flights$ = this.store.pipe(select(getFlightsGrouped));
    this.airports$ = this.store.pipe(select(getAirportsMap));
    this.pristineState$ = this.store.pipe(select(getPristineState));
    this.loadingState$ = this.store.pipe(select(getLoadingState));
    this.errorResponse$ = this.store.pipe(select(getErrorResponse), filter(x => !!x),
      tap(her => {
        snackBar.open(her.name, her.statusText, {
          duration: 3000
        });
      }));
  }
}
