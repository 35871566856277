import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { FlightsQuery } from './../../model/flights.model';
import { Airport } from './../../model/airport.model';

export interface TimeMode {
  value: string;
}

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})

export class SearchPanelComponent implements OnInit, OnChanges {
  @Input() airports: Airport[];
  @Output() searchClicked = new EventEmitter<FlightsQuery>();
  formGroup: FormGroup;
  filteredDepartureAirports: Observable<Airport[]>;
  filteredArrivalAirports: Observable<Airport[]>;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.createForm();
    // TODO leak here
    this.filteredArrivalAirports = this.formGroup.controls.arrivalAirport.valueChanges
      .pipe(
        startWith(''),
        map(input => this._filterAirports(input))
      );
    this.filteredDepartureAirports = this.formGroup.controls.departureAirport.valueChanges
      .pipe(
        startWith(''),
        map(input => this._filterAirports(input))
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.airports && this.airports.length) {
      this.formGroup.patchValue({
        departureAirport: this.airports.find(airport => airport.code === 'DUB'),
        arrivalAirport: this.airports.find(airport => airport.code === 'WRO'),
      });
    }
  }

  private _filterAirports(input: string | Airport): Airport[] {
    if (typeof input === 'string') {
      const filterValue = input.toLowerCase();

      return this.airports
        .filter(({name, code}) =>
          name.toLowerCase().includes(filterValue) ||
          code.toLowerCase().includes(filterValue)
        );
    }

    return input ? [input] : this.airports;
  }

  createForm() {
    const date = new Date();
    const dayMs = 24 * 60 * 60 * 1000;
    const from = new Date(date.getTime() + (30 * dayMs));
    const to = new Date(date.getTime() + (35 * dayMs));
    this.formGroup = this.formBuilder.group({
      departureAirport: '',
      arrivalAirport: '',
      dateFrom: from,
      dateTo: to,
      layoverFrom: '2',
      layoverTo: '6',
      timeMode: 'LOCAL'
    });
  }

  onClick() {
    const {dateFrom, dateTo} = this.formGroup.value;
    this.searchClicked.emit({
      ...this.formGroup.value,
      arrivalAirport: this.formGroup.value.arrivalAirport.code,
      departureAirport: this.formGroup.value.departureAirport.code,
      dateFrom: this.formatDate(dateFrom),
      dateTo: this.formatDate(dateTo)
    });
  }

  getAirportName(airport: Airport): string {
    return airport.name;
  }

  private formatDate(date: Date): string {
    const day = date.getDate();
    const calculatedDay = day < 10 ? `0${day}` : day.toString();
    const month = date.getMonth() + 1;
    const calculatedMonth = month < 10 ? `0${month}` : month.toString();

    return `${date.getFullYear()}-${calculatedMonth}-${calculatedDay}`;
  }
}
