import { Airport } from './../model/airport.model';
import { AirportActionTypes, AirportActions } from './airport.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface AirportState {
  items: Airport[];
  iataToName: Map<string, string>;
}

export const initialState: AirportState = {
  items: [],
  iataToName: new Map<string, string>()
};

function airportMapper(airports) {
  if (airports && airports.length) {
    return airports.map(({ code, name }) => ({
      code,
      name
    })).sort((a1, a2) => (a1.name < a2.name ? -1 : 1));
  }
  return [];
}

function iataToAirportMap(airports) {
  if (airports && airports.length) {
    return airports.map(({ code, name }) => [code, name]);
  }
  return [];
}

export function airportReducer(state = initialState, action: AirportActions) {
  switch (action.type) {
    case AirportActionTypes.FETCH_START:
      return initialState;
    case AirportActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        items: airportMapper(action.payload),
        iataToName: new Map(iataToAirportMap(action.payload)),
      };
    default:
      return state;
  }
}

export const getAirportState = createFeatureSelector<AirportState>('airport');

export const getAirports = createSelector(getAirportState, (airportState: AirportState) => airportState.items);
export const getAirportsMap = createSelector(getAirportState, (airportState: AirportState) => airportState.iataToName);
