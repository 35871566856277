import {Flight, Flights} from './../model/flights.model';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FlightsActions, FlightsActionTypes} from './flights.actions';
import {HttpErrorResponse} from '@angular/common/http';

export interface FlightsState {
  items: Flight[];
  itemsByDate: Map<string, Flight[][]>;
  errorResponse: HttpErrorResponse;
  loading: boolean;
  pristine: boolean;
}

export const initialState: FlightsState = {
  items: [],
  itemsByDate: null,
  errorResponse: null,
  loading: false,
  pristine: true
};

export function flightsReducer(state = initialState, action: FlightsActions) {
  switch (action.type) {
    case FlightsActionTypes.FETCH_START:
      return {
      ...initialState,
      loading: true,
      pristine: false
    };
    case FlightsActionTypes.FETCH_ERROR:
      return {
        ...state,
        errorResponse: action.errorResponse,
        loading: false,
        pristine: false
      };
    case FlightsActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        items: action.payload.map(f => f.flights),
        itemsByDate: groupByDate(action.payload),
        errorResponse: null,
        loading: false,
        pristine: false
      };
    default:
      return state;
  }
}

function groupByDate(payload: Flights[]) {
  if (!payload.length) {
    return null;
  }

  return payload
    .map(f => f.flights)
    .sort((a1, a2) => (a1[0].departureDateTime < a2[0].departureDateTime ? -1 : 1))
    .reduce((acc, obj) => {
      const key = obj[0].departureDateTime.substring(0, 10);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
}

export const getFlightsState = createFeatureSelector<FlightsState>('flights');

export const getFlights = createSelector(getFlightsState, (flightsState: FlightsState) => flightsState.items);
export const getFlightsGrouped = createSelector(getFlightsState, (flightsState: FlightsState) => flightsState.itemsByDate);
export const getErrorResponse = createSelector(getFlightsState, (flightsState: FlightsState) => flightsState.errorResponse);
export const getPristineState = createSelector(getFlightsState, (flightsState: FlightsState) => flightsState.pristine);
export const getLoadingState = createSelector(getFlightsState, (flightsState: FlightsState) => flightsState.loading);
