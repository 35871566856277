import { Action } from '@ngrx/store';

export enum AirportActionTypes {
  FETCH_START = '[Airport] FETCH_START',
  FETCH_SUCCESS = '[Airport] FETCH_SUCCESS',
  Reset = '[Airport] Reset'
}

export class FetchAirportStart implements Action {
  readonly type = AirportActionTypes.FETCH_START;
}

export class FetchedAirportSuccess implements Action {
  readonly type = AirportActionTypes.FETCH_SUCCESS;
  constructor(public payload: any) {}
}

export type AirportActions = FetchAirportStart | FetchedAirportSuccess;
