import { FlightsQuery } from './../../model/flights.model';
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { FetchFlightsStart } from './../../state/flights.actions';
import { getAirports } from './../../state/airport.reducer';
import { FetchAirportStart } from './../../state/airport.actions';

@Component({
  selector: 'app-search-panel-container',
  templateUrl: './search-panel-container.component.html',
  styleUrls: ['./search-panel-container.component.scss']
})
export class SearchPanelContainerComponent {
  airports$: Observable<any>;
  constructor(private store: Store<any>) {
    this.airports$ = this.store.pipe(select(getAirports));
    this.store.dispatch(new FetchAirportStart());
  }

  handleSearchClick(queryModel: FlightsQuery) {
    this.store.dispatch(new FetchFlightsStart(queryModel));
  }
}
