import { FlightsQuery, Flights } from './../model/flights.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { switchMap, map, catchError } from 'rxjs/operators';

import { FetchFlightsStart, FlightsActionTypes, FetchedFlightsSuccess, FetchedFlightsError } from './flights.actions';
import { of } from 'rxjs';

const FLIGHTS_API = 'https://services-api.ryanair.com/timtbl/v3/journeys';

@Injectable()
export class FlightsEffects {
  constructor(private actions$: Actions, private http: HttpClient) {
  }

  @Effect()
  fetchFlights = this.actions$.pipe(
    ofType<FetchFlightsStart>(FlightsActionTypes.FETCH_START),
    switchMap(action =>
      this.http
        .get<Flights[]>(this.createFlightUrl(action.payload))
        .pipe(map(result => new FetchedFlightsSuccess(result)),
          catchError((t: HttpErrorResponse) => (of(new FetchedFlightsError(t)))))
    )
  );

  private createFlightUrl({arrivalAirport, dateFrom, dateTo, departureAirport, layoverFrom, layoverTo, timeMode}: FlightsQuery): string {
    let url = `${FLIGHTS_API}/${departureAirport}/${arrivalAirport}?departureDateFrom=${
      dateFrom
    }&departureDateTo=${dateTo}&key=c75263dd5ffbda4698843257b2e68fbb&timeMode=${timeMode}`;
    if (layoverFrom != null) {
      url = `${url}&layoverFrom=${layoverFrom}`;
    }
    if (layoverTo != null) {
      url = `${url}&layoverTo=${layoverTo}`;
    }
    return url;
  }
}
